<template>
  <div class="card">
    <div class="card-body text-center d-flex align-items-center flex-column">
      <div class="card--cae--content mb-auto">
        <div class="mb-1">
          <img
            :src="icrea.avatarUrl"
            alt="name icrea"
            class="round"
            height="40"
            width="40"
          >
        </div>
        <h3>{{ icrea.name }}</h3>
        <p v-if="icrea.hosts">
          <span
            v-for="host, index in icrea.hosts"
            :key="host.id + '-' + index"
            class="badge bg-light-primary mt-1 ms-25"
          >{{ host.name }}</span>
        </p>
        <p v-if="icrea.department">
          <span class="badge bg-light-primary">{{ icrea.department.name }}</span>
        </p>
        <p v-if="icrea.workplaces">
          <span class="badge bg-light-primary">{{ icrea.workplaces }}</span>
        </p>
        <p class="text-muted">
          {{ icrea.documents }} docs
        </p>
      </div>
      <a
        class="btn btn-small btn-primary"
        :href="$router.resolve({ name: 'cae.view-icrea', params: {id: icrea.id}, query: { actAs: $route.query.actAs } }).href"
        target="_blank"
      >View profile</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    icrea: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
    }),
  },
  mounted() {

  },
}
</script>
